<template>
  <base-loader v-if="loading"></base-loader>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("form.trader_form") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form @submit.prevent="submitForm">
              <!-- Errors Handleing -->
              <div
                class="row bg-danger py-3 mb-3"
                v-if="validationErrors.length"
              >
                <div
                  class="col-md-6"
                  v-for="(error, index) in validationErrors"
                  :key="index"
                >
                  <span class="text-white mr-2 error-handling">{{
                    error
                  }}</span>
                </div>
              </div>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("form.trader_information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.name')"
                      :placeholder="$t('form.name')"
                      input-classes="form-control-alternative"
                      v-model="traderData.name"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.email')"
                      placeholder="abc@example.com"
                      input-classes="form-control-alternative"
                      v-model="traderData.email"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.sector')"
                      :placeholder="$t('form.sector')"
                      input-classes="form-control-alternative"
                      v-model="traderData.sector"
                    />
                  </div>
                  <div class="col-md-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.logo")
                      }}</label>
                      <input
                        type="file"
                        class="form-control form-control-alternative"
                        v-on:change="uploadLogo"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.description")
                      }}</label>
                      <textarea
                        class="form-control form-control-alternative"
                        v-model="traderData.description"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <!-- Information -->
              <h6 class="heading-small text-muted mb-4">
                {{ $t("form.additional information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.account_bank_number')"
                      :placeholder="$t('form.account_bank_number')"
                      input-classes="form-control-alternative"
                      v-model="traderData.account_bank_number"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.phone')"
                      :placeholder="$t('form.phone')"
                      input-classes="form-control-alternative"
                      v-model="traderData.phone_number"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.supplier_code')"
                      :placeholder="$t('form.supplier_code')"
                      input-classes="form-control-alternative"
                      v-model="traderData.supplier_code"
                    />
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t("form.category")
                    }}</label>
                    <select
                      class="form-control form-control-alternative"
                      v-model="traderData.trader_types_id"
                    >
                      <option value="" selected>
                        {{ $t("form.choose_category") }}
                      </option>
                      <option
                        v-for="(category, index) in categories"
                        :key="index"
                        :value="category.id"
                      >
                        {{ category.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.lat')"
                      :placeholder="$t('form.lat')"
                      input-classes="form-control-alternative"
                      v-model="traderData.lat"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.lng')"
                      :placeholder="$t('form.lng')"
                      input-classes="form-control-alternative"
                      v-model="traderData.lng"
                    />
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t("form.city")
                    }}</label>
                    <select
                      class="form-control form-control-alternative"
                      v-model="traderData.city"
                    >
                      <option value="" selected>
                        {{ $t("form.choose_city") }}
                      </option>
                      <option
                        v-for="(city, index) in cities"
                        :key="index"
                        :value="city.id"
                      >
                        {{ city.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.avatar")
                      }}</label>
                      <input
                        type="file"
                        class="form-control form-control-alternative"
                        v-on:change="uploadAvatar"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t("form.gender")
                    }}</label>
                    <select
                      class="form-control form-control-alternative"
                      v-model="traderData.gender"
                    >
                      <option value="" selected>
                        {{ $t("form.choose_gender") }}
                      </option>
                      <option value="male">
                        {{ $t("form.male") }}
                      </option>
                      <option value="female">
                        {{ $t("form.female") }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      type="password"
                      alternative=""
                      :label="$t('form.password')"
                      :placeholder="$t('form.password')"
                      input-classes="form-control-alternative"
                      v-model="traderData.password"
                    />
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <div class="col-lg-12 mt-4">
                <button type="submit" class="btn btn-success d-block">
                  {{ this.$route.params.id ? $t("form.save") : $t("form.add") }}
                </button>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "trader-form",
  data() {
    return {
      loading: false,
      validationErrors: [],
      cities: [],
      categories: [],

      traderData: {},
    };
  },
  created() {
    if (this.$route.params.id) {
      this.getTrader(this.$route.params.id);
    }
    this.getCategories("admin/administration/traderTypes/index");
    this.getCites("admin/administration/cities/index");
  },
  methods: {
    validation: function () {
      this.validationErrors = [];
      if (
        this.traderData.name &&
        this.traderData.email &&
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.traderData.email
        ) &&
        this.traderData.sector &&
        this.traderData.description &&
        this.traderData.city &&
        this.traderData.trader_types_id &&
        this.traderData.account_bank_number &&
        this.traderData.lat &&
        this.traderData.lng &&
        this.traderData.phone_number &&
        this.traderData.gender &&
        this.traderData.supplier_code
      ) {
        return true;
      } else {
        this.validationErrors.push(
          `${this.$t("validation.all fields required")}`
        );
        // if (!this.traderData.name) {
        //   this.validationErrors.push(`${this.$t("validation.name required")}`);
        // }
        // if (!this.traderData.email) {
        //   this.validationErrors.push(`${this.$t("validation.email required")}`);
        // }
        // if (
        //   this.traderData.email &&
        //   !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        //     this.traderData.email
        //   )
        // ) {
        //   this.validationErrors.push(
        //     `${this.$t("validation.email not valid")}`
        //   );
        // }
        // if (!this.traderData.sector) {
        //   this.validationErrors.push(
        //     `${this.$t("validation.sector required")}`
        //   );
        // }
        // if (!this.traderData.gender) {
        //   this.validationErrors.push(
        //     `${this.$t("validation.gender required")}`
        //   );
        // }
        // if (!this.traderData.password && !this.$route.params.id) {
        //   this.validationErrors.push(
        //     `${this.$t("validation.password required")}`
        //   );
        // }
        return false;
      }
    },
    submitForm() {
      if (this.validation() == false) {
        this.$swal({
          position: "center",
          icon: "error",
          title: `${this.$t("validation.faild")}`,
          showConfirmButton: false,
          timer: 1200,
        });
        return false;
      }
      this.loading = true;
      if (this.$route.params.id) {
        this.traderData._method = "put";
        let formData = this.toFormData(this.traderData);
        this.updateTrader(formData);
      } else {
        let formData = this.toFormData(this.traderData);
        this.addTrader(formData);
      }
    },
    // Get Trader Information
    getTrader: function () {
      this.loading = true;
      axios
        .get("admin/administration/traders/" + this.$route.params.id)
        .then((response) => {
          if (!response.data.error) {
            this.traderData = {};
            this.traderData.trader_id = response.data.data[0].id;
            this.traderData.name = response.data.data[0].name;
            this.traderData.email = response.data.data[0].email;
            this.traderData.sector = response.data.data[0].sector;
            this.traderData.gender = response.data.data[0].gender;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get All Categories
    getCategories: function ($url) {
      this.loading = true;
      axios
        .get($url)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.categories = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get All Cites
    getCites: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.cities = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Add Trader
    addTrader: function (formData) {
      this.loading = true;
      axios
        .post("admin/administration/traders/store", formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 1) {
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
              setTimeout(() => {
                this.$router.push("/traders");
              }, 1500);
            } else {
              this.validationErrors.push(response.data.message);
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Update Trader
    updateTrader: function (formData) {
      this.loading = true;
      axios
        .post("admin/administration/traders/update", formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 1) {
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
              setTimeout(() => {
                this.$router.push("/traders");
              }, 1500);
            } else {
              this.validationErrors.push(response.data.message);
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get Image to Add
    uploadLogo(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.traderData.logo = files[0];
    },
    uploadAvatar(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.traderData.avatar = files[0];
    },
    toFormData: function (obj) {
      var fd = new FormData();
      for (var i in obj) {
        fd.append(i, obj[i]);
      }
      return fd;
    },
  },
};
</script>
